<template>
  <div class="page">智能接待报表</div>
</template>

<script>
export default {
  name: "Reception",
};
</script>

<style>
</style>